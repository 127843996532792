// Header.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AllApi from "../../../api/allApi";
import dropArrow from "../../../assets/images/dropArrow.png";
import allApi from "../../../api/allApi";
import { useTheme } from "../../../app/component/ThemeContext";

export default function Header({ heading, showBreadcrumb }) {
  const navigate = useNavigate();
  const [nameInitials, setNameInitials] = useState("");
  const [userName, setUserName] = useState("");
  const [showBadge, setShowBadge] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const notificationRef = useRef(null);
  const toggleRef = useRef(null);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [mainNotifications, setMainNotifications] = useState([]);
  const [showOnlyUnread, setShowOnlyUnread] = useState(false);
  let isToggleClick = false;
  //const [bellIcon,setBellIcon] = useState(false);

  useEffect(() => {
    if (showNotifications) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotifications]);


  useEffect(() => {
    const theme_value = sessionStorage.getItem("theme_key");
    if (theme_value == "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
      document.getElementById("theme").checked = true;
    } else {
      document.documentElement.setAttribute("data-theme", "light");
    }
    const userName = sessionStorage.getItem("username");
    setUserName(userName);
    themeChange();

    if (userName) {
      const [firstName, lastName] = userName.split(" ");
      const initials = `${firstName.charAt(0).toUpperCase()}${lastName
        .charAt(0)
        .toUpperCase()}`;
      setNameInitials(initials);
    }
    fetchNotifications();
    fetchUnreadNotifications();
  }, []);

  // useEffect(()=>{

  // },[]);

  const logout = () => {
    const allApi = new AllApi();
    allApi
      .logout("api/auth/logout")
      .then((response) => {
        sessionStorage.removeItem("sessionToken");
      })
      .catch((error) => {
        console.error("Logout Error:", error);
      });
    navigate("/");
  };

  const themeChange = () => {
    const toggleSwitch = document.getElementById("theme");
    function switchTheme(e) {
      if (e.target.checked) {
        document.documentElement.setAttribute("data-theme", "dark");
        sessionStorage.setItem("theme_key", "dark");
      } else {
        document.documentElement.setAttribute("data-theme", "light");
        sessionStorage.setItem("theme_key", "light");
      }
    }
    toggleSwitch.addEventListener("change", switchTheme, false);
  };

  const fetchNotifications = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/notification/allNotifications");
      const allFetchedNotifications = response.map((notification) => ({
        id: notification.id || notification.notificationId,
        description: notification.description,
        createdAt: notification.createdAt,
        deviceName: notification.deviceName || "N/A",
        isUnread: notification.isUnread || false,
      }));

      setAllNotifications(allFetchedNotifications);
      setNotifications(allFetchedNotifications.slice(0, 5));
      //  setNotificationCount(allFetchedNotifications.length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const markAsRead = async (notificationIds, allFetchedNotifications) => {
    try {
      const api = new allApi();
      const payload = notificationIds;
      await api.postRequest("api/notification/markNotifications", "", payload);

      setAllNotifications(allNotifications.filter((n) => !notificationIds.includes(n.id)));
      setNotifications(
        allNotifications.filter((n) => !notificationIds.includes(n.id))
      );
      setNotificationCount((prevCount) => prevCount - notificationIds.length);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };


  const fetchUnreadNotifications = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/notification/allUnreadNotifications");

      const unreadNotificationsList = response.map((notification) => ({
        id: notification.id || notification.notificationId,
        description: notification.description,
        createdAt: notification.createdAt,
        deviceName: notification.deviceName || "N/A",
      }));

      setUnreadNotifications(unreadNotificationsList);
      setNotificationCount(unreadNotificationsList.length);

      if (showOnlyUnread) {
        setNotifications(unreadNotificationsList.length > 0 ? unreadNotificationsList.slice(0, 5) : []);
      }
    } catch (error) {
      console.error("Error fetching unread notifications", error);
    }
  };


  const handleMarkAllAsRead = async () => {
    try {
      // Get all unread notification IDs
      const unreadNotificationIds = allNotifications
        .filter((notification) => notification.isUnread)
        .map((notification) => notification.id);

      // Mark unread notifications as read
      await markAsRead(unreadNotificationIds);

      // Update the state of allNotifications
      const updatedNotifications = allNotifications.map((notification) => ({
        ...notification,
        isUnread: false, // Mark all as read
      }));

      setAllNotifications(updatedNotifications);

      // Update the notifications state based on the current toggle
      if (showOnlyUnread) {
        setNotifications([]); // Show no notifications when "Only Unread" is toggled
      } else {
        setNotifications(updatedNotifications.slice(0, 5)); // Show top 5 notifications
      }

      setNotificationCount(0); // All notifications are now read
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };


  const handleSingleRead = (notificationId) => {
    if (showOnlyUnread) {
      markAsRead([notificationId]);
    }
  };

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
    //setBellIcon((prev)=> !prev);
  };

  const handleClickOutside = (event) => {
    if (isToggleClick) {
      isToggleClick = false; // Reset flag
      return;
    }

    if (
      notificationRef.current && !notificationRef.current.contains(event.target) &&
      toggleRef.current && !toggleRef.current.contains(event.target)
    ) {
      setShowNotifications(!showNotifications);
      setShowOnlyUnread(false);
      setNotifications(allNotifications.slice(0, 5));
      // setBellIcon(false);
    }
  };

  const handleUnreadClick = () => {
    setShowOnlyUnread((prev) => {
      const updatedState = !prev;

      // Update notifications based on the new state
      const updatedNotifications = updatedState
        ? allNotifications.filter((n) => n.isUnread).slice(0, 5) // Only unread
        : allNotifications.slice(0, 5); // Top 5 regardless of read status

      setNotifications(updatedNotifications);
      return updatedState; // Toggle showOnlyUnread
    });
  };


  const handleViewAllClick = () => {
    navigate("/notification");
    setShowNotifications(false);
  };

  const handleClick = () => {
    setShowBadge(true);
    navigate("/notification");
  };
  return (
    <header id="top-bar">
      <div className="header-title">
        {heading}
        {/* {showBreadcrumb && (
          <p>
            <a href="#">Heading</a> <i className="fa fa-angle-right" aria-hidden="true"></i> {heading}
          </p>
        )} */}
      </div>
      <div className="header-control">
        <div className="switch-box">
          <label className="ice-switch">
            <input
              type="checkbox"
              className="theme"
              id="theme"
              checked={theme === "dark"}
              onChange={toggleTheme}
            />
            <span className="slider"></span>
          </label>
          <span>Dark Mode</span>
        </div>
        <div className="header-notification" >
          {/* <span className="notifications-count">
            {notificationCount > 0 && <span>{notificationCount}</span>}
            <i className="icon notifications"></i>
          </span> */}
          <i className="icon notifications notifications-count" onClick={handleNotificationClick}>
            {notificationCount > 0 && <span>{notificationCount}</span>}
          </i>
          {showNotifications && (
            <div ref={notificationRef} className="top-notification">
              <div className="notification-title">
                Notifications
                <div className="switch-box" ref={toggleRef}>
                  <label className="ice-switch">
                    <input
                      type="checkbox"
                      className="theme"
                      checked={showOnlyUnread}
                    // onChange={handleUnreadClick}                   
                    />
                    <span className="slider" onClick={handleUnreadClick} ></span>
                  </label>
                  <span >Only show unread</span>
                </div>
              </div>
              <div className="notification-box">
                {showOnlyUnread ? (
                  unreadNotifications.length > 0 ? (
                    unreadNotifications.map((notification, index) => (
                      <div
                        className="notification-item"
                        key={index}
                        onClick={() => {
                          handleSingleRead(notification.id);
                          handleViewAllClick();
                        }}
                      >
                        <div>
                          <strong>{notification.deviceName || "N/A"}</strong>{" "}
                          <span>
                            {new Date(notification.createdAt).toLocaleString()}
                          </span>{" "}
                        </div>
                        <div>
                          <span>{notification.description}</span>{" "}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-notifications-message">No unread notifications</div>
                  )
                ) : notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <div
                      className="notification-item"
                      key={index}
                      onClick={() => {
                        handleSingleRead(notification.id);
                        handleViewAllClick();
                      }}
                    >
                      <div>
                        <strong>{notification.deviceName || "N/A"}</strong>{" "}
                        <span>
                          {new Date(notification.createdAt).toLocaleString()}
                        </span>{" "}
                      </div>
                      <div>
                        <span>{notification.description}</span>{" "}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-notifications-message">
                    No notifications to display
                  </div>
                )}
              </div>

              <div className="notification-footer">
                <span onClick={handleViewAllClick}>
                  View All
                </span>{" "}
                <span className="mark-all" onClick={handleMarkAllAsRead}>
                  Mark All as Read
                </span>{" "}
              </div>
            </div>
          )}
        </div>

        <div className="name-post">
          <strong>{userName}</strong>
        </div>
        <div className="user-access">
          <div data-bs-toggle="dropdown" aria-expanded="false">
            <div className="header-profile-user">
              <span className="profile-user dark">{nameInitials}</span>
              <span className="drop">
                <img src={dropArrow} width="12" alt="" />
              </span>
            </div>
          </div>
          <div className="dropdown-menu dropdown-menu-right">
            <button className="dropdown-item">Profile Settings</button>
            <button className="dropdown-item" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
