import React, { useState, useEffect } from "react";
import ConfigNet from "./ConfigNet";
import WiredSettings from "./WiredSettings";
import IpConfiguration from "./IpConfiguration";
import WirelessSettings from "./WirelessSettings";
import allApi from "../../../../api/allApi";

function ConfigureNetwork() {
  const [currentStep, setCurrentStep] = useState(1);
  const [configNetData, setConfigNetData] = useState({});
  const [wiredSettingsData, setWiredSettingsData] = useState(null);
  const [wirelessSettingsData, setWirelessSettingsData] = useState({});
  const [ipConfigurationData, setIpConfigurationData] = useState({});
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [formData, setFormData] = useState({
    vlanId: "",
    serialNumber: "",
    taggedPorts: "",
    untaggedPorts: "",
  });
  const [schedule, setSchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [loading, setLoading] = useState(true);
  const [ssidList, setSsidList] = useState([]);
  const [basicSwitchInfo, setBasicSwitchInfo] = useState(null);
  const [refreshing, setRefreshing] = useState(true);
  const [assignedPorts, setAssignedPorts] = useState({});

  useEffect(() => {
    fetchSwitchInfo();
  }, []);

  const fetchSwitchInfo = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const switchInfoResponse = await api.getRequest(
        "api/vlan/get/allSwitchBasicInfo"
      );
      console.log(switchInfoResponse, "info");
      if (
        switchInfoResponse &&
        switchInfoResponse.portMembers &&
        switchInfoResponse.portMembers.length > 0
      ) {
        const serialNumber = switchInfoResponse.portMembers[0].serialNo;
        setFormData((prev) => ({ ...prev, serialNumber }));
        setBasicSwitchInfo(switchInfoResponse);
      }
    } catch (error) {
      console.error("Failed to fetch switch info:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const createScheduleSetting = (schedule) => {
    return {
      schDays: "1111111", // Customize as per selected days
      schOnTime0: schedule.Monday[0]?.startTime || "0000",
      schOffTime0: schedule.Monday[0]?.endTime || "0000",
      schOnTime1: schedule.Tuesday[0]?.startTime || "0000",
      schOffTime1: schedule.Tuesday[0]?.endTime || "0000",
      schOnTime2: schedule.Wednesday[0]?.startTime || "0000",
      schOffTime2: schedule.Wednesday[0]?.endTime || "0000",
      schOnTime3: schedule.Thursday[0]?.startTime || "0000",
      schOffTime3: schedule.Thursday[0]?.endTime || "0000",
      schOnTime4: schedule.Friday[0]?.startTime || "0000",
      schOffTime4: schedule.Friday[0]?.endTime || "0000",
      schOnTime5: schedule.Saturday[0]?.startTime || "0000",
      schOffTime5: schedule.Saturday[0]?.endTime || "0000",
      schOnTime6: schedule.Sunday[0]?.startTime || "0000",
      schOffTime6: schedule.Sunday[0]?.endTime || "0000",
    };
  };

  const validateStep = async (step) => {
    console.log(`Validating step ${step}`);
    switch (step) {
      case 1:
        console.log('Step 1 valid:', isStep1Valid);
        return isStep1Valid;
      case 2:
        const isValidStep2 = wiredSettingsData !== null && wiredSettingsData.someRequiredField !== undefined;
        console.log('Step 2 valid:', isValidStep2);
        return isValidStep2;
      case 3:
        const isValidStep3 = wirelessSettingsData.ssid && wirelessSettingsData.ssid.trim() !== "";
        console.log('Step 3 valid:', isValidStep3);
        return isValidStep3;
      case 4:
        const isValidStep4 = ipConfigurationData.ipAddress && ipConfigurationData.subnetMask;
        console.log('Step 4 valid:', isValidStep4);
        return isValidStep4;
      default:
        return false;
    }
  };
  
  const handleNext = async () => {
    // Check if the current step is Step 2 and handle access port within handleSubmit
    if (currentStep === 2) {
      // Do nothing extra here since access port handling is done in handleSubmit
      // You can call handleSubmit directly if needed or just continue to the next step
      setCurrentStep(currentStep + 1);
      return;
    }
  
    // If the current step is Step 4, submit the form (Step 3 logic will also be handled by handleSubmit)
    if (currentStep === 4) {
      await handleSubmit();
    } else {
      // Move to the next step
      setCurrentStep(currentStep + 1);
    }
  };
  
  // Function to handle the submission of all data.
  const handleSubmit = async () => {
    try {
      setLoading(true);
  
      // Step 1: Call the `api/vlan/add/vlan` endpoint
      const api = new allApi();
      const vlanResponse = await api.postRequest("api/vlan/add/vlan", "", {
        vlan: {
          igmpSnooping: "1",
          name: configNetData.vlanName,
          overrideTrafficPriority: "0",
          qosConfig: configNetData.networkType,
          trafficClass: "0",
          vlanId: configNetData.vlanId,
          vlanNwDesc: configNetData.description,
          vlanNwName: configNetData.networkName,
          vlanType: "6",
          voipOptimization: "0",
          vlanNwName: "Network_22",
        },
      });
  
      if (!vlanResponse.success) {
        throw new Error("Step 1 API request failed: " + (await vlanResponse.text()));
      }
  
      // Step 2: Call `api/vlan/add/accessPort` only if Step 1 is successful
      const accessPortResponse = await api.postRequest(
        "api/vlan/add/accessPort",
        "",
        {
          vlanId: parseInt(formData.vlanId, 10),
          SerialNumber: formData.serialNumber,
          vlanMembers: {
            // Configuration for port members
            lagMembers: {
              portGrp: [],
              tagged: formData.taggedPorts.split(",").map((port) => port.trim()),
              untagged: formData.untaggedPorts.split(",").map((port) => port.trim()),
            },
            portMembers: [
              {
                deviceId: "667d4168cd6b7b3e023755e5",
                taggedPorts: formData.taggedPorts.split(",").map((port) => port.trim()),
                untaggedPorts: formData.untaggedPorts.split(",").map((port) => port.trim()),
              },
            ],
          },
        }
      );
  
      if (!accessPortResponse.ok) {
        throw new Error("Step 2 API request failed: " + (await accessPortResponse.text()));
      }
  
      // Step 3: Call `api/wireless/configuration/addSsid`
      const ssidResponse = await api.postRequest(
        "api/wireless/configuration/addSsid?networkId=6001",
        "",
        {
          ssid: formData.ssid,
          broadcastStatus: formData.broadcastStatus,
          band: formData.band.join(", "),
          vlanId: formData.vlanId,
          bandSteeringSt: formData.bandSteeringSt,
          fastRoamingSt: formData.fastRoamingSt,
          clientIsolation: formData.clientIsolation,
          allowLocalUIAccess: formData.allowLocalUIAccess,
          scheduleEnabled: formData.scheduleEnabled,
          kvrStatus: formData.kvrStatus,
          securityAuthentication: formData.securityAuthentication,
          password: formData.password,
          schedule:
            formData.scheduleEnabled === "1"
              ? {
                  scheduleName: formData.selectedSchedule || "custom_schedule",
                  allDay: formData.allDay || "0",
                  scheduleSetting: createScheduleSetting(schedule),
                }
              : undefined,
        }
      );
  
      if (!ssidResponse.ok) {
        throw new Error("Step 3 API request failed: " + (await ssidResponse.text()));
      }
  
      // If all steps succeed, you can finalize or show success message here
    } catch (error) {
      console.error("An error occurred:", error.message);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ConfigNet
            data={configNetData}
            setData={setConfigNetData}
            setIsValid={setIsStep1Valid}
          />
        );
      case 2:
        return (
          <WiredSettings
            data={wiredSettingsData}
            setData={setWiredSettingsData}
            serialNumber={formData.serialNumber}
            formData={formData}
            setFormData={setFormData}
            assignedPorts={assignedPorts}
            setAssignedPorts={setAssignedPorts}
            // Removed handleAccessPort since it's no longer needed
          />
        );
      case 3:
        return (
          <WirelessSettings
            data={wirelessSettingsData}
            setData={setWirelessSettingsData}
            ssidList={ssidList}
          />
        );
      case 4:
        return (
          <IpConfiguration
            data={ipConfigurationData}
            setData={setIpConfigurationData}
          />
        );
      default:
        return null;
    }
  };
  
  
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCancel = () => {
    // Handle cancel action
    alert("Cancelled");
  };

  return (
    <div className="configure-network">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Configure Network</h3>
              </div>
              <div className="inc-card-body">
                <div className="inc-report">
                  <div className="report-tab">
                    {[1, 2, 3, 4].map((step) => (
                      <div
                        className={`step ${
                          currentStep === step
                            ? "selected"
                            : currentStep > step
                            ? "completed"
                            : ""
                        }`}
                        key={step}
                        onClick={() => handleStepClick(step)}
                      >
                        <div
                          className="s-text"
                          style={{ cursor: "pointer", color: "purple" }}
                        >
                          <span>STEP {step}</span>
                          <strong>
                            {step === 1
                              ? "Configure Network"
                              : step === 2
                              ? "Wired Settings"
                              : step === 3
                              ? "Wireless Settings"
                              : "IP Configuration"}
                          </strong>
                        </div>
                        <div className="s-dot">
                          <i
                            className={`fa ${
                              currentStep >= step ? "fa-check" : "fa-circle"
                            }`}
                            aria-hidden="true"
                          ></i>
                        </div>
                        {step < 4 && (
                          <i className="line">
                            <span></span>
                          </i>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="report-section">
                    <div className="report-container">{renderStep()}</div>
                    <div className="report-button">
                      {currentStep > 1 && (
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      )}
                      {currentStep === 1 && (
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-fill"
                        onClick={handleNext}
                      >
                        {currentStep === 4 ? "Submit" : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfigureNetwork;
