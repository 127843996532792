import React from "react";

function shareDiagnosticInformation() {
  return (
    <div>
      <div className="tab-form">
        <p className="mt-2">Enabling Secure Diagnostics Mode connects your device to INC's diagnostics server,<br/> 
          allowing support personnel to remotely diagnose your system.</p>
          <p>By enabling Secure Diagnostics Mode, you agree to the terms of the <a href="#" className="link">INC Remote Access Policy</a>.</p>
          <div className="row mt-4">
          <div className="col-lg-6">
            <div className="switch-box">
              <strong>Secure Diagnostics Mode</strong>
              <label className="ice-switch">
                <input type="checkbox" />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">
          Cancel
        </button>
        <button type="submit" className="text-btn primary-btn">
          Save
        </button>
      </div>

      {/* <div className="customModal">
        <div className="c-modal-dialog modal-dialog-centered small">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Secure Diagnostic Mode</h5>
              <button type="button" className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p>NETGEAR recommends that you only enable this feature if a support engineer requests access.</p>
              <h3>Do you want to continue?</h3>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline">No, don't enable this</button>
              <button type="submit" class="text-btn primary-btn">OK</button>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default shareDiagnosticInformation;