import React, { useEffect, useState } from "react";
import Loader from "../common/Loader";
import { useTheme } from "../ThemeContext";

function SystemSettings() {
  const [loading, setLoading] = useState(true);
  const { theme } = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card ch-100">
            <div className="inc-card-title">
              <h3>System Information</h3>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : (
                <div className="white-bg">
                  <iframe
                    src={`https://inc.aws.vvdncloud.com:3001/d/jMrPmiSnk/docker-containers?orgId=1&refresh=10s&kiosk&theme=${theme}`}
                    width="100%"
                    height="735px"
                    frameBorder="0"
                    title="Docker Containers"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemSettings;
